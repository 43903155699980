<script setup>
const {options} = useGlobalStore();
const {toggleCart} = useCartStore();
const {itemsInCart} = storeToRefs(useCartStore());
const {currentPage} = storeToRefs(useGlobalStore());
const nuxtApp = useNuxtApp();
const route = useRoute();
const show = ref(false);
const {$activeModalsBus} = useNuxtApp();
const mobile_show_contact = ref(false);
const {cart} = useCart();
watch(() => route.fullPath, () => {
  show.value = false;
  mobile_show_contact.value = false;
});
nuxtApp.hook("page:loading:start", () => {

  show.value = false;
  mobile_show_contact.value = false;
});
onMounted(() => {
  addEventListener("scroll", (event) => {
    if (window.scrollY >= 50) {
      setTimeout(() => {
        show.value = false;
        mobile_show_contact.value = true;
      }, 200);
    }
  });
});


</script>
<template>
  <div class="floating-socials no_mobile">
    <nuxt-link target="_blank" to="https://www.facebook.com/sasa.shtihim" class="soc-icon">
      <SvgoFacebook/>
    </nuxt-link>
    <nuxt-link target="_blank" to="https://www.instagram.com/sasa_shtihim/" class="soc-icon">
      <SvgoInstagram/>
    </nuxt-link>
    <nuxt-link target="_blank" to="https://wa.me/?9720555522553" class="soc-icon">
      <SvgoWhatsapp/>
    </nuxt-link>
    <nuxt-link target="_blank" to="https://youtube.com/@sasa_shtihim" class="soc-icon">
      <SvgoYoutube/>
    </nuxt-link>
  </div>
  <div class="fixed-chat no_mobile">
    <div class="fixed-chat__inner" v-show="show">
      <a href="#" @click.prevent="$activeModalsBus.event('openPopupPhone')">
        <img
            loading="lazy"
            src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/icons/mobile/phone-call.png"
        />
      </a>
      <a href="https://wa.me/9720555522553">
        <img loading="lazy"
             src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/svg/whattsapp-new.svg"/>
      </a>
      <a href="javascript:void(0);" @click.prevent="$activeModalsBus.event('openPopup')">
        <img loading="lazy"
             src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/svg/chat desktop.svg"/>
      </a>
      <a href="https://t.me/sasa_shtihim">
        <img loading="lazy"
             src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/svg/telegram new.svg"/>
      </a>
      <a href="javascript:void(0)" style="padding-left: 30px" @click="show = false">
        <img loading="lazy"
             src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/icons/mobile/close.png"/>
      </a>
    </div>
    <img
        loading="lazy"
        src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/fixed-chat.png"
        class="no_mobile"
        @click="show = !show"
        alt="אייקון המסמל תפריט צד נפתח וכולל לוגו של הודעה ווואטסאפ עליו"
    />
    <img
        loading="lazy"
        src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/mobile-chat.png"
        class="mobile_only"
    />
  </div>
  <div class="fixed-chat mobile_only " v-if="route.name !='cart' && route.name !='checkout'"
       :class="{active : mobile_show_contact }" href="javascript:void(0)"
       target="_blank">
    <img class="fixed-chat__main"
         src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/contact us.svg"
         @click="$activeModalsBus.event('openPopupContact')">
  </div>
  <div class="fixed-cart no_mobile" v-show="route.name !='cart' && route.name !='checkout'" @click="toggleCart">
    <div class="ic-cart ml-4 mt-2">
      <span :class="`ic-cart-num text-white`">{{ itemsInCart }}</span>
      <SvgoCartIc/>
    </div>
  </div>
</template>
